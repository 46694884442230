var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "authorization" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.authorization"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return [
                _c("h3", { staticClass: "mb-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("applications.application-authorization")) +
                      " "
                  )
                ]),
                ["Instructor", "Program Admin"].includes(_vm.role)
                  ? _c("applicant-on-behalf-authorize", {
                      attrs: {
                        nextStepDisabled: _vm.nextStepDisabled,
                        applicant: _vm.applicant
                      },
                      on: {
                        "update:nextStepDisabled": function($event) {
                          _vm.nextStepDisabled = $event
                        },
                        "update:next-step-disabled": function($event) {
                          _vm.nextStepDisabled = $event
                        }
                      }
                    })
                  : _c("applicant-self-authorize", {
                      attrs: {
                        nextStepDisabled: _vm.nextStepDisabled,
                        applicant: _vm.applicant
                      },
                      on: {
                        "update:nextStepDisabled": function($event) {
                          _vm.nextStepDisabled = $event
                        },
                        "update:next-step-disabled": function($event) {
                          _vm.nextStepDisabled = $event
                        }
                      }
                    })
              ]
            },
            proxy: true
          },
          {
            key: "col-2",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ])
      }),
      _c("v-divider", { staticClass: "mx-4" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-2", attrs: { "offset-md": "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-2 ml-4 mr-5",
                  on: { click: _vm.previousStep }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1 grey--text text--darken-2" },
                    [_vm._v("mdi-chevron-left-circle")]
                  ),
                  _vm._v(_vm._s(_vm.$t("applications.back")))
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "my-2",
                  attrs: { color: "secondary", disabled: _vm.nextStepDisabled },
                  on: { click: _vm.nextStep }
                },
                [
                  _vm._v(_vm._s(_vm.$t("applications.continue")) + " "),
                  _c(
                    "v-icon",
                    { staticClass: "ml-1 secondary--text text--lighten-2" },
                    [_vm._v("mdi-chevron-right-circle")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }