<template>
    <v-form ref="authorization">
        <form-section
            :sectionTitle="$t('applications.authorization')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <h3 class="mb-2">
                    {{ $t("applications.application-authorization") }}
                </h3>
                <applicant-on-behalf-authorize
                    :nextStepDisabled.sync="nextStepDisabled"
                    :applicant="applicant"
                    v-if="['Instructor', 'Program Admin'].includes(role)"
                ></applicant-on-behalf-authorize>
                <applicant-self-authorize
                    v-else
                    :nextStepDisabled.sync="nextStepDisabled"
                    :applicant="applicant"
                ></applicant-self-authorize>
            </template>
            <template v-slot:col-2></template>
        </form-section>
        <v-divider class="mx-4"></v-divider>
        <v-row>
            <v-col offset-md="2" class="mt-2">
                <v-btn class="my-2 ml-4 mr-5" @click="previousStep"
                    ><v-icon class="mr-1 grey--text text--darken-2"
                        >mdi-chevron-left-circle</v-icon
                    >{{ $t("applications.back") }}</v-btn
                >
                <v-btn
                    color="secondary"
                    class="my-2"
                    @click="nextStep"
                    :disabled="nextStepDisabled"
                    >{{ $t("applications.continue") }}
                    <v-icon class="ml-1 secondary--text text--lighten-2"
                        >mdi-chevron-right-circle</v-icon
                    >
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
export default {
    name: "applicant-authorize",
    components: {
        ApplicantSelfAuthorize: () => import("./ApplicantSelfAuthorize.vue"),
        ApplicantOnBehalfAuthorize: () =>
            import("./ApplicantOnBehalfAuthorize.vue"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        step: {
            type: [Number, String],
            required: true,
        },
        role: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            nextStepDisabled: true,
        };
    },
    methods: {
        nextStep() {
            this.$emit("next", parseInt(this.step) + 1);
        },
        previousStep() {
            this.$emit("previous", parseInt(this.step) - 1);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>